<template>
  <header class="header_area" :class="isActiveMenu ? 'active' : ''">
    <div class="container">
      <div class="header_wrap">
        <a href="#hero" class="logo">$GLVR</a>
        

        <!-- Start Nav -->
        <div class="navArea">
          <div class="social-link-wrap">
            <a
              v-for="(socialLink, i) in templateHeader.socialLinks"
              :key="i"
              :href="socialLink.link"
              class="social-link"
              target="_blank"
            >
              <img
                v-if="socialLink.icon"
                :src="socialLink.icon"
                :alt="socialLink.type"
              />
            </a>
          </div>
          
          <!-- End Social -->
          <div class="cta-wrap">
            <!-- Añadir el botón de conexión de TON aquí -->
            <TonConnectButton class="ton-wallet-button" />

            

            <!-- Mostrar detalles de la wallet conectada -->
            <div v-if="wallet">
              <span>Connected wallet: {{ wallet.name }}</span><br />
              <span>Device: {{ wallet.device.appName }}</span>
            </div>

            <div class="purchase-btn">
              <label for="amount">Amount to send (nanotons):</label>
              <input v-model="amountToSend" id="amount" type="number" placeholder="Enter amount"/>
            </div>

              <!-- Sección para enviar transacción -->
            <button @click="sendTransaction" class="primary-btn purchase-btn">Send Transaction</button>

            <!-- Selector de idioma -->
            <div class="language-selector">
              <label for="language-select">Language:</label>
              <select id="language-select" @change="onLanguageChange($event.target.value)">
                <option value="en">English</option>
                <option value="ru">Russian</option>
              </select>
            </div>

            

            <!-- Botón de compra existente -->
            <a class="primary-btn purchase-btn">
              <span>Send</span>
            </a>
            <!-- Menu Show/Close Button -->
            <button class="menu-btn" @click="isActiveMenu = !isActiveMenu">
              <span class="bars-wrap">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
              </span>
            </button>
          </div>
        </div>
        <!-- End Nav -->
      </div>
      <nav class="nav-menu-wrap">
        <div class="nav-links-wrap">
          <a
            v-for="(navContent, i) in templateHeader.navLinksContents"
            :key="i"
            :href="navContent.path"
            class="nav-link"
            @click="isActiveMenu = false"
            ><span>{{ navContent.title }}</span></a
          >
        </div>
        <img :src="templateHeader.navImg.src" class="pouchfella" :alt="templateHeader.navImg.alt" />
        <div class="social-link-wrap two">
          <a
            v-for="(socialLink, i) in templateHeader.socialLinks"
            :key="i"
            :href="socialLink.link"
            class="social-link"
            target="_blank"
          >
            <img
              v-if="socialLink.icon2"
              :src="socialLink.icon2"
              :alt="socialLink.type"
            />
          </a>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { TonConnectButton, useTonAddress, useTonWallet, useTonConnectModal, useTonConnectUI } from '@townsquarelabs/ui-vue';
import { ref } from 'vue';

export default {
  name: "templateHeader",
  components: {
    TonConnectButton
  },
  setup() {
    // Obtener la dirección en formatos amigable y sin procesar
    const userFriendlyAddress = useTonAddress(true);
    const rawAddress = useTonAddress(false);


    // Obtener detalles de la wallet conectada
    const wallet = useTonWallet();

    // Obtener funciones para abrir/cerrar modal y el estado del modal
    const { state, open, close } = useTonConnectModal();

       // Obtener la instancia de TonConnect UI y setOptions
       const { tonConnectUI, setOptions } = useTonConnectUI();
       const amountToSend = ref(""); 

       // Función para cambiar el idioma de la UI
    const onLanguageChange = (lang) => {
      setOptions({ language: lang });
    };


     // Crear una transacción para enviar TON
     const myTransaction = {
      validUntil: Math.floor(Date.now() / 1000) + 120, // Expira en 60 segundos
      messages: [
        {
          address: "EQCpCDbnMR8scMq1auQjXWVJp0rI6-h5y6CizE7dUyHeNNX-",
          amount: amountToSend.value  // Cantidad en nanotones
        }
      ]
    };

    // Función para enviar la transacción
    const sendTransaction = async () => {
  if (wallet) {
    try {
      await tonConnectUI.sendTransaction(myTransaction);
      console.log("Transacción enviada correctamente");
    } catch (error) {
      if (error.message.includes("Reject request")) {
        console.error("Transacción rechazada por el usuario");
      } else {
        console.error("Error al enviar la transacción:", error);
      }
    }
  } else {
    console.error("No hay ninguna wallet conectada");
  }
};

    return {
      userFriendlyAddress,
      rawAddress,
      wallet,
      state,
      open,
      close,
      sendTransaction,
      onLanguageChange
    };
  },
  props: ["templateHeader"],
  data() {
    return {
      isActiveMenu: false
    };
  }
};
</script>

<style scoped>
.ton-wallet-button {
  margin-right: 15px;
}
</style>
