<template>
  
  <section class="hero-area" id="hero">
    <div v-if="heroData.heroBgPattern.src" class="hero-bg-pattern">
      <div class="pattern-box">
        <img
          :src="heroData.heroBgPattern.src"
          class="hero-pattern"
          :alt="heroData.heroBgPattern.alt"
        />
      </div>
    </div>
    
    <!-- End Hero Pattern -->
    <div class="hero-contents">
      <div class="hero-contents-wrap">
        <h6
          v-if="heroData.subTitle"
          class="sub-title aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="400"
        >
          {{ heroData.subTitle }}
        </h6>
        <div class="headlines">
          
          <h1
            v-if="heroData.title"
            class="hero-title aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {{ heroData.title }}
          </h1>
            <div class="accordion my-accordion" id="accordionPanelsStayOpenExample">
              <div class="accordion-item custom-accordion-item">
                <div class="social-link-wrap2">
            <a
              v-for="(socialLink, i) in heroData.socialLinks"
              :key="i"
              :href="socialLink.link"
              class="social-link"
              target="_blank"
            >
              <img
                v-if="socialLink.icon"
                :src="socialLink.icon"
                :alt="socialLink.type"
              />
            </a>
          </div>
                <div
                  id="panelsStayOpen-collapseOne"
                  class="accordion-collapse collapse show"
                >
                  <div class="accordion-body custom-accordion-body">
                    <strong>100 million sale for</strong> price of 0.015
                  </div>
                
                  <!-- Countdown Timer with labels for Days, Hours, Minutes, Seconds -->
                  <div class="countdown-timer">
                    <div class="countdown-item">
                      <span class="countdown-value">{{ countdown.days }}</span>
                      <span class="countdown-label">Days</span>
                    </div>
                    <div class="countdown-item">
                      <span class="countdown-value">{{ countdown.hours }}</span>
                      <span class="countdown-label">Hours</span>
                    </div>
                    <div class="countdown-item">
                      <span class="countdown-value">{{ countdown.minutes }}</span>
                      <span class="countdown-label">Minutes</span>
                    </div>
                    <div class="countdown-item">
                      <span class="countdown-value">{{ countdown.seconds }}</span>
                      <span class="countdown-label">Seconds</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <p
            v-if="heroData.description"
            class="describtion aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            {{ heroData.description }}
          </p>
          <div
            class="cta-wrap aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <a
              href="https://t.me/TQJAqPAYmq83MTY0"
              target="_blank"
              class="primary-btn"
              ><span>Buy </span>
              <img src="@/assets/img/telegram.png" alt="Telegram" class="telegram-icon"/>
              </a
            >
            <a
                href="/gelverWhitePapper.pdf"
                class="secondery-btn"
                target="_blank"
                download="GelverCoinInfo.pdf"
              >
                Download the Whitepapper
              </a>
          </div>
        </div>
      </div>
    </div>

  
    <div class="chart-wrap2">
    <div class="cta-wrap aos-init aos-animate" style="max-width: 540px;">
  <div class="row g-0">
    <div class="col-md-4">
      <img src="@/assets/img/gelver.png" class="img-fluid rounded-start" alt="Telegram">
    </div>
    <div class="col-md-8">
      <div>
        <h5 class="card-title token-word">Token Sale</h5>
        <div class="input-group mb-3">
          <label for="amount" class="input-group-text">You pay:</label>
          <input v-model="amountToSend" type="number" class="form-control custom-input" id="amount" placeholder="Enter amount in TON">
        </div>
        <div class="current-price-group mb-1">
          <label class="current-price" for="price">Current Price per Token: </label>
          <span class="current-price-number">${{ currentPrice }}</span>
        </div>
        <div class="current-price-group mb-1">
          <label class="current-price" for="price">Next Price per Token: </label>
          <span class="current-price-number">0.024</span>
          <span class="price-increase">+33.33%</span>
        </div>
        <div class="input-group mb-3">
          <label for="received" class="input-group-text">You receive:</label>
          <input :value="receivedAmount" id="received" class="form-control" readonly />
        </div>
        <button @click="sendTransaction" class="primary-btn purchase-btn">Send Transaction</button>

      </div>
    </div>
  </div>
</div>
</div>



  

    <!-- Start Sub-Hero -->
    <div class="sub-hero-area">
      <div class="gtItNw-img-wrap">
        <div class="img-box">
          <img
            v-if="heroData.subHeroData.gtItNwImg1.src"
            :src="heroData.subHeroData.gtItNwImg1.src"
            :alt="heroData.subHeroData.gtItNwImg1.alt"
          />
          <img
            v-if="heroData.subHeroData.gtItNwImg2.src"
            :src="heroData.subHeroData.gtItNwImg2.src"
            :alt="heroData.subHeroData.gtItNwImg2.alt"
            class="caption"
          />
        </div>
      </div>
      <div class="address">
        <div class="address-wrap">
          <img
            v-if="heroData.subHeroData.cpyAdressImage"
            :src="heroData.subHeroData.cpyAdressImage"
            alt="Copy Icon"
          />
          <span class="cpy-status">{{ copiedText ? 'Copied!: ' : 'Token address: ' }}</span>
          <input
            type="text"
            id="cpy-text"
            ref="copyText"
            value="UQBNC7Gh39BJRyMZGIFa8vTt7n1HTgwpPo00UV0C_qzSMypj"
          />
          <button id="cpyBtn" @click="copy"></button>
        </div>
      </div>
      <!-- End Address -->
      <div class="getItNow-wrap">
        <div class="title-wrap">
          <h2
            v-if="
              heroData.subHeroData.gtItNwImg3.src ||
              heroData.subHeroData.gtItNwTitle
            "
            class="getItNow-title"
          >
            {{ heroData.subHeroData.gtItNwTitle }}
            <img
              v-if="heroData.subHeroData.gtItNwImg3.src"
              :src="heroData.subHeroData.gtItNwImg3.src"
              :alt="heroData.subHeroData.gtItNwImg3.alt"
            />
          </h2>
          <h2 v-if="heroData.subHeroData.gtItNwTitle2" class="getItNow-title">
            {{ heroData.subHeroData.gtItNwTitle2 }}
          </h2>
        </div>
        
        <div class="gtItNw-area">
          <div class="gtItNw-links-wrap">
            <a
              v-for="(gtItNwLink, index) in heroData.subHeroData.gtItNwLinks"
              :key="index"
              :href="gtItNwLink.path_url"
              :class="gtItNwLink.cus_class"
              :target="gtItNwLink.target"
              >{{ gtItNwLink.title }}</a
            >
          </div>
        </div>
      </div>
      <!-- End Get It Now -->
    </div>
    <!-- End Sub-Hero -->
  </section>
</template>
  
  <script>


import { useTonAddress, useTonWallet, useTonConnectModal, useTonConnectUI } from '@townsquarelabs/ui-vue';
import { ref, onMounted } from 'vue';

export default {
  name: "HeroArea",
  props: ["heroData"],
  data: () => {
    return {
      amount: 0, 
      totalSold: 0, 
      saleStages: [
        { maxTokens: 100000000, price: 0.015 },
        { maxTokens: 400000000, price: 0.028 },
        { maxTokens: 700000000, price: 0.034 },
        { maxTokens: 1000000000, price: 0.044 },
      ],
      currentPrice: 0.015, 
    };
  },

  setup() {
    // Wallet and TonConnect hooks
    const userFriendlyAddress = useTonAddress(true);
    const rawAddress = useTonAddress(false);
    const wallet = useTonWallet();
    const { state, open, close } = useTonConnectModal();
    const { tonConnectUI, setOptions } = useTonConnectUI();
    const amountToSend = ref("");

    // Countdown timer state
    const countdown = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });

    let endDate = localStorage.getItem('endDate');

    if (!endDate) {
      endDate = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
      localStorage.setItem('endDate', endDate); // Guardar la fecha en localStorage
    } else {
      endDate = parseInt(endDate); // Convertir a número
    }

    const startCountdown = () => {
      setInterval(() => {
        const now = new Date().getTime();
        const timeLeft = endDate - now;

        if (timeLeft >= 0) {
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

          countdown.value = {
            days,
            hours,
            minutes,
            seconds,
          };
        } else {
          clearInterval(startCountdown); // Parar el contador cuando llegue a 0
        }
      }, 1000); // Actualizar cada segundo
    };

    onMounted(() => {
      startCountdown(); // Start the countdown on mount
    });

    // Function to handle language change
    const onLanguageChange = (lang) => {
      setOptions({ language: lang });
    };

    // Transaction logic
    const sendTransaction = async () => {
      if (wallet) {
        const myTransaction = {
          validUntil: Math.floor(Date.now() / 1000) + 120, 
          messages: [
            {
              address: "EQCpCDbnMR8scMq1auQjXWVJp0rI6-h5y6CizE7dUyHeNNX-", 
              amount: amountToSend.value 
            }
          ]
        };

        try {
          await tonConnectUI.sendTransaction(myTransaction);
          console.log("Transaction successfully sent");
        } catch (error) {
          if (error.message.includes("Reject request")) {
            console.error("Transaction rejected by the user");
          } else {
            console.error("Error sending transaction:", error);
          }
        }
      } else {
        console.error("No wallet connected");
      }
    };

    return {
      userFriendlyAddress,
      rawAddress,
      wallet,
      state,
      open,
      close,
      sendTransaction,
      onLanguageChange,
      amountToSend,
      countdown, // Bind countdown state to the component
    };
  },
  computed: {
    receivedAmount() {
      return (this.amount / this.currentPrice).toFixed(4);
    },
  },
};
</script>
  
  <style scoped></style>
  