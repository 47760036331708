import { createApp } from 'vue';
import { TonConnectUIPlugin } from '@townsquarelabs/ui-vue'; // Añadir el plugin de TonConnect
import App from './App.vue';
import router from './router/routes.js';
import $ from 'jquery';
import VueGtag from "vue-gtag-next";  // Import vue-gtag


// #All Stylesheet
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "@/assets/css/main.css";
import "@/assets/css/responsive.css";

// Crear la aplicación de Vue
const app = createApp(App);

// Usar el plugin TonConnectUIPlugin antes de montar la aplicación
app.use(TonConnectUIPlugin, {
  manifestUrl: "http://gelvercoin.site/tonconnect-manifest.json" // Asegúrate de usar la URL correcta del manifest
});
app.use(VueGtag, {
  property: {
    id: "G-XHF8S1CQ73",  
  },
  isEnabled: true, // Enable tracking
  pageTrackerScreenviewEnabled: true, // Track route changes
  router,  // Bind Vue Router for automatic page tracking
});

// Montar la aplicación con el router
app.use(router).mount('#app');

// Definir jQuery globalmente
window.$ = $;
window.jQuery = $;
