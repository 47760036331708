<template>
  <main id="page-main">
    <!-- Start Header -->
    <template-header :templateHeader="templateHeader" />
    <!-- End Header -->

    <!-- Start Hero -->
    <hero-area :heroData="heroData" />
    <!-- End Hero -->

    <!-- Start About -->
    <about-area :aboutData="aboutData" />
    <!-- End About -->

    <!-- Start Features -->
    <features-area :featureData="featureData" />
    <!-- End Features -->

    <!-- Start Tokenoimcs -->
    <tokenomics-area :tokenomicsData="tokenomicsData" />
    <!-- End Tokenoimcs -->

    <!-- Start Road Map -->
    <road-map-area :roadMapData="roadMapData" />
    <!-- End Road Map -->

    <!-- Start How To Buy -->
    <how-to-buy-area :howToBuyData="howToBuyData" />
    <!-- End How To Buy -->

    <!-- Start Faq -->
    <faq-area :faqData="faqData" />
    <!-- End Faq -->

    <!-- Start Footer -->
    <footer-area :footerData="footerData" />
    <!-- End Footer -->
  </main>
</template>

<script>
import templateHeader from "@/components/sections/templateHeader.vue";
import heroArea from "@/components/sections/heroSection.vue";
import aboutArea from "@/components/sections/aboutSection.vue";
import featuresArea from "@/components/sections/featuresSection.vue";
import tokenomicsArea from "@/components/sections/tokenomicsSection.vue";
import roadMapArea from "@/components/sections/roadMapSection.vue";
import howToBuyArea from "@/components/sections/howToBuySection.vue";
import faqArea from "@/components/sections/faqSection.vue";
import footerArea from "@/components/sections/footerSection.vue";

export default {
  name: "HomeView",
  components: {
    templateHeader,
    heroArea,
    aboutArea,
    featuresArea,
    tokenomicsArea,
    roadMapArea,
    howToBuyArea,
    faqArea,
    footerArea,
  },

  data: () => {
    return {
      // #Header Contents
      templateHeader: {
        navImg: {
          src: require("@/assets/img/illustration-5.png"),
          alt: "pouchfella",
        },
        socialLinks: [
          {
            type: "X",
            link: "https://x.com/Gelvernumber1?t=TBGhMRp7oln-yti7-jhKqA&s=09",
            icon: require("@/assets/img/x.png"),
            icon2: require("@/assets/img/telegram-2.png"),
          },

          {
            type: "Facebook",
            link: "https://www.facebook.com/profile.php?id=61566593774278&mibextid=ZbWKwL",
            icon: require("@/assets/img/facebook.png"),
            icon2: require("@/assets/img/discord-2.png"),
          },

          {
            type: "Instagram",
            link: "https://www.instagram.com/gelver_coin/profilecard/?igsh=MWhiY2w2NHF1a3JzOA",
            icon: require("@/assets/img/instagram.png"),
            icon2: require("@/assets/img/notion-2.png"),
          },
        ],

        navLinksContents: [
          {
            title: "About",
            path: "#about",
          },

          {
            title: "Features",
            path: "#Features",
          },

          {
            title: "Tokenomics",
            path: "#Tokenomics",
          },

          {
            title: "Roadmap",
            path: "#Roadmap",
          },

          {
            title: "How To Buy",
            path: "#HowToBuy",
          },

          {
            title: "Faq",
            path: "#Faq",
          },
        ],
      },

      // #Hero Contents
      heroData: {
        subTitle: "Introducing",
        title: "gelver",
        description:
          "The only cryptocurrency you will HODL till the next bear run.",
        heroBgPattern: {
          src: require("@/assets/img/shape.png"),
          alt: "hero-pattern",
        },
        socialLinks: [
          {
            type: "X",
            link: "https://x.com/Gelvernumber1?t=TBGhMRp7oln-yti7-jhKqA&s=09",
            icon: require("@/assets/img/x.png"),
            icon2: require("@/assets/img/telegram-2.png"),
          },

          {
            type: "Facebook",
            link: "https://www.facebook.com/profile.php?id=61566593774278&mibextid=ZbWKwL",
            icon: require("@/assets/img/facebook3.png"),
            icon2: require("@/assets/img/discord-2.png"),
          },

          {
            type: "Instagram",
            link: "https://www.instagram.com/gelver_coin/profilecard/?igsh=MWhiY2w2NHF1a3JzOA",
            icon: require("@/assets/img/instagram.png"),
            icon2: require("@/assets/img/notion-2.png"),
          },
        ],
        subHeroData: {
          gtItNwImg1: {
            src: require("@/assets/img/gelver.png"),
            alt: "Illustration",
          },
          gtItNwImg2: {
            src: require("@/assets/img/guau.png"),
            alt: "Caption",
          },
          gtItNwImg3: {
            src: require("@/assets/img/shape-2.png"),
            alt: "fsdfsdfs",
          },
          cpyAdressImage: require("@/assets/img/copy.png"),
          gtItNwTitle: "Get it",
          gtItNwTitle2: "Now on:",
          cpyPath: "0x532f27101965dd16442E59d40670FaF5eBB142E4",
          gtItNwLinks: [
            {
              title: "Gate.io",
              path_url: "https://www.gate.io/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Bitget",
              path_url: "https://www.bitget.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "BingX",
              path_url: "https://www.bingx.com/en-us/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "LBANK",
              path_url: "https://www.lbank.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Gate.io",
              path_url: "https://www.gate.io/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Bitget",
              path_url: "https://www.bitget.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "BingX",
              path_url: "https://www.bingx.com/en-us/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "LBANK",
              path_url: "https://www.lbank.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Gate.io",
              path_url: "https://www.gate.io/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Bitget",
              path_url: "https://www.bitget.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "BingX",
              path_url: "https://www.bingx.com/en-us/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "LBANK",
              path_url: "https://www.lbank.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
          ],
        },
      },

      // #About Contents
      aboutData: {
        secTitle: "What is this about?",
        stryHeadlineTitle: "Nothing is Impossible with Gelver",
        stryRocketIllustration: require("@/assets/img/galvercoin.png"),
        stryDescriptions: "<p> Gelver Coin is an innovative digital currency tailored for savvy investors in the precious metals market. Designed for the effortless buying and selling of gold and silver, Gelver Coin offers unmatched speed and efficiency in transactions while maintaining minimal fees..</p> <p>What sets Gelver Coin apart is its strategic value alignment, consistently positioned between the price of a gram of gold and a gram of silver. This stability makes it an attractive and secure option for those looking to diversify their portfolios. By leveraging Gelver Coin, investors can navigate the precious metals landscape with confidence and ease, making it a compelling choice for modern trading.Gelver Coin is an innovative digital currency tailored for savvy investors in the precious metals market. Designed for the effortless buying and selling of gold and silver, Gelver Coin offers unmatched speed and efficiency in transactions while maintaining minimal fees.</p>"
      },

      // #Features Contents
      featureData: {
        secSubTitle: "We got really",
        secTitle: {
          txt1: "Strong",
          txt2: "Foundation",
        },
        featureLists: [
          {
            img: require("@/assets/img/mundo.png"),
            title: "Strategic <br/> Partnerships",
            description:
              "Gelver Coin’s success is built upon strong partnerships with leading cryptocurrency exchanges, financial platforms, and key players within the TON community. These collaborations provide Gelver with expanded reach, liquidity, and support from industry-leading institutions.",
          },
          {
            img: require("@/assets/img/galvercoin2.png"),
            title: "Community <br/> Support",
            description:
              "At the heart of Gelver Coin is a vibrant, engaged community. Gelver prioritizes active engagement, open communication, and transparent decision-making, empowering users to have a voice in the coin’s future development.",
          },
          {
            img: require("@/assets/img/tothemoon.png"),
            title: "Growing <br/> Ecosystem",
            description:
              "Gelver Coin’s ecosystem is rapidly expanding, with a growing network of users, developers, and investors all contributing to its evolution. This dynamic environment creates unmatched opportunities for collaboration, networking, and mutual support.",
          },
        ],
      },

      // #Tokenomics Contents
      tokenomicsData: {
        secSubTitle: "It's always about that",
        secTitle: "Tokenomics",
        bigImage: require("@/assets/img/gelver.png"),
        shape: require("@/assets/img/shape-4.png"),
        tokenomicsLinks: [
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
        ],
        chartLegends: [
          {
            chartLegend: "Pre-sale website",
          },
          {
            chartLegend: "Pre-sale pinksale",
          },
          {
            chartLegend: "Team and advisors",
          },
          {
            chartLegend: "CEX",
          },
          {
            chartLegend: "Staking",
          },
          {
            chartLegend: "Marketing",
          },
          {
            chartLegend: "Airdrop",
          },
          {
            chartLegend: "Reserve",
          },
        ],
        chartOptions: {
          data: [10, 10, 15, 10, 10, 15, 5, 25],
          labels: ["10% ", "10%", "15%", "10%", "10%", "15", "5%", "25%"],
          backgroundColor: [
            "rgba(121, 204, 158, 1)",
            "rgba(196, 51, 225, 1)",
            "rgba(226, 254, 165, 1)",
            "rgba(248, 255, 232, 1)",
            "rgba(233, 122, 244, 1)",
            "rgba(208,151,72,1)",
            "rgba(128,130,134,1)",
            "rgb(90,13,225)",

          ],
          type: 'doughnut',
          tooltip: true
        }
      },

      // #RoadMap Contents
      roadMapData: {
        secTitle1: "Road",
        secTitle2: "Map",
        secSubTitle1: "We are on the",
        secSubTitle2: "to heaven.",
        nextBtnImg: {
          src: require("@/assets/img/next-btn.png"),
          alt: "nextBtn",
        },
        prevBtnImg: {
          src: require("@/assets/img/prev-btn.png"),
          alt: "prevBtn",
        },
        coinfellaImg: {
          src: require("@/assets/img/coin.png"),
          alt: "Coinfella",
        },
        roadMapSliders: [
          {
            title: "Stage 1:",
            subTitle: `"Conceptualization"`,
            cus_class: "orange",
            eventTypes: [
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Research",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Team Building",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Whitepaper",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Compliance",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Community",
              },
            ],
          },

          {
            title: "Stage 2:",
            subTitle: `"Development"`,
            cus_class: "cyan",
            eventTypes: [
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Ton",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "wallets",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Website",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Testing",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Listings",
              },
            ],
          },

          {
            title: "Stage 3:",
            subTitle: `"Launch"`,
            cus_class: "majestic-purple",
            eventTypes: [
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Launch Token",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Presale",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Website",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Pinksale",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Feedback",
              },
            ],
          },

          {
            title: "Stage 4:",
            subTitle: `"Growth"`,
            cus_class: "deep-moss-green",
            eventTypes: [
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Governance",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Incentives",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Partnerships",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Scaling",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Vision",
              },
            ],
          },
        ],
      },

      // #How_To_Buy Contents
      howToBuyData: {
        secTitle: "How to buy?",
        promoMessage: `Have you gone through all of the steps before? Great! Then just hit the button down below and fill up your bag with gelver right now.`,
        img1: require("@/assets/img/illustration-6.png"),
        img2: require("@/assets/img/illustration-7.png")
      },

      // #Faq Contents
      faqData: {
        secTitle: "Got Some Faq?",
        faqs: [
          {
            title: "What is gelver and how does it work?",
            content: "Gelver it's coin equal between 1 gram of gold and 1 gram of silver.Our presale will be ready soon",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "Where can I buy gelver?",
            content: "You can buy this Gelvercoin directly from the Telegram or with your Ton wallet",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "What makes Gever unique?",
            content: "Gelver it's coin equal between 1 gram of gold and 1 gram of silver",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "How can I buy Gelver?",
            content: "You can directly contact us by our social media or buying directly from the Ton page or our Telegram group.",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "What are the potential risks associated with investing in Gelver?",
            content: "This is not an investment advice, but you should check it out since Gold and Silver always wins.",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "Can I check Gelver community?",
            content: "Yes, you can actually check our community since we have Telegram, Instagram, X and Facebook on the top of the corner",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },
        ],
      },

      // #Footer Contents
      footerData: {
        secTitle: "Gelver",
        CTRPBtnTitle: "→ Cryptocurrency Trading Risk Policy",
        footerImg: require("@/assets/img/cajafuerte.svg"),
        footerLinks: [
          {
            title: "About",
            path: "#about",
            cus_class: "",
          },

          {
            title: "Features",
            path: "#Features",
            cus_class: "",
          },

          {
            title: "Tokenomics",
            path: "#Tokenomics",
            cus_class: "",
          },

          {
            title: "Roadmap",
            path: "#Roadmap",
            cus_class: "",
          },

          {
            title: "How To Buy",
            path: "#HowToBuy",
            cus_class: "",
          },

          {
            title: "FAQ",
            path: "#Faq",
            cus_class: "",
          },
        ],
        policyDetails: {
          policy_title: "Cryptocurrency Trading Risk Policy:",
          policyLists: [
            {
              title: "Introduction:",
              description: "Before engaging in trading or holding cryptocurrencies through Gelver, it is crucial to understand the risks associated with digital currencies and Ton technology. This document outlines the key risks that you should consider. We strongly advise you to read this policy carefully and consult with professional advisors before making any investment decisions.",
              cus_class: "",
            },

            {
              title: "1. Investment Risks",
              description: "Cryptocurrencies carry a high level of risk. The value of cryptocurrencies is extremely volatile and may fluctuate significantly within very short periods of time. Such volatility can result from changes in market dynamics, regulatory actions, technological advancements, or other factors. There is a substantial risk that you could lose all of your investment. You should only invest funds that you can afford to lose entirely.",
              cus_class: "",
            },

            {
              title: "2. Regulatory Uncertainty",
              description: "The legal and regulatory environment surrounding cryptocurrencies is still developing and is subject to significant uncertainty. Future changes in laws and regulations could materially impact the cryptocurrency in terms of its use, exchange, and value. It is your responsibility to understand the regulatory requirements that apply to you and to comply with them.",
              cus_class: "",
            },

            {
              title: "3. No Legal Protection",
              description: "Transactions in cryptocurrencies may not be reversible, and, therefore, losses due to fraudulent or accidental transactions may not be recoverable. Unlike traditional banks or credit card providers, there are generally no protections that can reverse a transaction if an error or fraud occurs.",
              cus_class: "",
            },

            {
              title: "4. Market Liquidity",
              description: "Liquidity in cryptocurrency markets can vary widely and lack of liquidity can lead to high volatility in prices. This could result in significant changes in the value of your cryptocurrency holdings and may make it difficult for you to sell your holdings at a reasonable price.",
              cus_class: "",
            },

            {
              title: "5. Technology Risks",
              description: "The technology underlying cryptocurrencies is Ton, which relies on the internet and advanced computer hardware and software. As such, it is susceptible to a range of disruptions, including operational failures, cyber attacks, or significant changes in technology. Such disruptions can lead to losses.",
              cus_class: "",
            },

            {
              title: "6. No Guarantee of Returns",
              description: "There is no guarantee that cryptocurrency will increase in value or that it will not decrease. Past performance is not indicative of future results. You should carefully consider whether trading or holding cryptocurrency aligns with your financial goals and risk tolerance.",
              cus_class: "",
            },

            {
              title: "7. Tax Liability",
              description: "There is no guarantee that cryptocurrency will increase in value or that it will not decrease. Past performance is not indicative of future results. You should carefully consider whether trading or holding cryptocurrency aligns with your financial goals and risk tolerance.",
              cus_class: "",
            },

            {
              title: "8. Expert Advice Recommended",
              description: "Trading cryptocurrencies involves significant risk. We recommend that you consult with financial, legal, and tax advisors to better understand the risks associated with cryptocurrencies.",
              cus_class: "",
            },

            {
              title: "9. KYC/AML Compliance",
              description: "You are required to comply with all applicable anti-money laundering (AML) and know your customer (KYC) regulations. Failure to do so may result in the suspension or termination of your account.",
              cus_class: "",
            },

            {
              title: "10. Use at Your Own Risk",
              description: "You acknowledge that you are using Gelver's services at your own risk, understanding the volatile nature of the cryptocurrency market.",
              cus_class: "",
            },

            {
              title: "Conclusino:",
              description: "By using Gelver, you acknowledge that you have read, understood, and agreed to the terms of this Cryptocurrency Trading Risk Disclosure Policy. If you have any questions or require further clarification, please contact us directly before using our services.",
              cus_class: "",
            },
          ],
        },
      },
    };
  }
};
</script>

<style scoped></style>
