<template>
  <section class="footer-area">
    <div class="title-wrap">
      <h2
        v-if="footerData.secTitle"
        class="getItNow-title aos-init aos-animate"
        data-aos="fade-up"
        data-aos-duration="500"
      >
        {{ footerData.secTitle }}
      </h2>
    </div>

    <div class="footer-links-wrap">
      <a
        v-for="(link, index) in footerData.footerLinks"
        :key="index"
        :href="link.path"
        class="footer-link"
        :class="link.cus_class"
        ><span v-if="link.title"> {{ link.title }}</span></a
      >
    </div>

    <!-- End Footer-Links -->

    <div class="cta-wrap-two">
      <a
        href="https://t.me/TQJAqPAYmq83MTY0"
        target="_blank"
        class="primary-btn promo-btn"
        ><span>Buy</span></a
      >
      <button class="CTRP-btn" @click="isPopoverOpen = true">
        <p v-if="footerData.CTRPBtnTitle">
          {{ footerData.CTRPBtnTitle }}
        </p>
      </button>
    </div>

    <div class="cpy-right-wrap">
      <div v-if="footerData.footerImg" class="cpy-img two">
        <img :src="footerData.footerImg" alt="Shape" />
      </div>
      <span class="cpy">
        <span style="opacity: 0; visibility: hidden">Widi</span>
        ©
        <span>gelver, 2024</span></span
      >

      <div v-if="footerData.footerImg" class="cpy-img">
        <img :src="footerData.footerImg" alt="Shape" />
      </div>

      <a
        href="https://www.menendez.dev"
        target="_blank"
        class="design-by"
        >Designed by Menensito</a
      >
    </div>

    <!-- Policy Popover -->
    <div class="CTRP-wrap" :class="{active: isPopoverOpen}">
      <div class="CTRP-contents-wrap">
        <h4 v-if="footerData.policyDetails.policy_title" class="policy-title">
          {{ footerData.policyDetails.policy_title }}
        </h4>
        <div
          v-for="(policy, index) in footerData.policyDetails.policyLists"
          :key="index"
          :class="policy.cus_class"
        >
          <h4 v-if="policy.title" class="policy-sub-title">
            {{ policy.title }}
          </h4>
          <p v-if="policy.description" class="policy-description">
            {{ policy.description }}
          </p>
        </div>

        <svg @click="isPopoverOpen = false" class="checkmark" id="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><path class="checkmark_check" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"></path><path class="checkmark_check2" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"></path></svg>
      </div>
    </div>
  </section>
</template>
              
<script>
export default {
  name: "footerSection",
  components: {},
  props: ["footerData"],
  data: () => {
    return {
      isPopoverOpen: false
    };
  }
};
</script>
              
  <style scoped></style>
              