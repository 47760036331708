<template>
    <section class="hw-to-by-area" id="HowToBuy">
        <div class="container">
            <div class="headline-wrap">
                <div class="title-wrap">
                    <h2 class="hero-title">
                        {{ howToBuyData.secTitle }}
                    </h2>
                </div>
                <div class="img-box">
                    <img
                        v-if="howToBuyData.img1"
                        src="@/assets/img/coin.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <!-- End Headline -->

            <div class="prcs-and-promo">
                <div class="promo-wrap">
                    <div class="promo">
                        <p
                            v-if="howToBuyData.promoMessage"
                            class="promo-message"
                        >
                            {{ howToBuyData.promoMessage }}
                        </p>
                        <img
                            v-if="howToBuyData.img2"
                            class="promo-img"
                            src="@/assets/img/gelver.png"
                            alt="Shape"
                        />
                    </div>
                    <a
                        href="https://t.me/TQJAqPAYmq83MTY0"
                        target="_blank"
                        class="primary-btn promo-btn"
                        ><span>Buy Gelver</span></a
                    >
                </div>
                <!-- End Promo -->
                <div class="prcs-wrap">
                    <div class="prcs-contents">
                        <div class="title-wrap">
                            <span
                                >Step <span class="prcs-stp-idx">1</span>:</span
                            >
                            <h4 class="prcs-title">
                                Choose a Crypto-Currency Exchange:
                            </h4>
                        </div>
                        <p class="description prcs">
                            Select a reputable exchange known for its security
                            measures, user-friendly interface, and support for
                            your specific cryptocurrency. Consider factors such
                            as trading fees, available trading pairs, and
                            customer support options to ensure the exchange
                            meets your needs.
                        </p>
                    </div>
                    <div class="prcs-nav-wrap">
                        <button class="secondery-btn prcsNext">
                            next step
                        </button>
                        <ul class="prcs-nav"></ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
          
<script>
export default {
    name: "HowToBuySection",
    components: {},
    props: ["howToBuyData"],
    data: () => {
        return {};
    },
    mounted() {
        const prcsNav = document.querySelector(".prcs-nav");
        const prcsStpIdx = document.querySelector(".prcs-stp-idx");
        const prcsTitle = document.querySelector(".prcs-title");
        const prcsDescription = document.querySelector(".description.prcs");
        const prcsNext2 = document.querySelector(".prcsNext");

        const stepsData = [
            {
              title: "Choose a Crypto-Currency Exchange:",
              description: "Select a reputable exchange known for its security measures, user-friendly interface, and support for your specific cryptocurrency. Consider factors such as trading fees, available trading pairs, and customer support options to ensure the exchange meets your needs.",
            },
            {
              title: "SIGN UP AND VERIFY:",
              description: "Register for an account on the chosen exchange by providing basic information such as your name, email address, and password. Complete any required verification steps, which may include providing identification documents and proof of address. This verification process helps ensure compliance with regulations and enhances account security.",
            },
            {
              title: "DEPOSIT FUNDS:",
              description: "Once your account is set up, deposit funds into it using one of the supported payment methods. This can include bank transfers, credit/debit cards, or other payment options offered by the exchange. Follow the instructions provided by the exchange to complete the deposit process, and be aware of any associated fees or processing times.",
            },
            {
              title: "PLACE YOUR ORDER:",
              description: "Navigate to the exchange's trading section and locate the trading pair for your cryptocurrency and dollars (e.g., TON/USD). Choose whether to place a market order, which executes immediately at the current market price, or a limit order, where you specify the price at which you want to buy. Enter the amount of cryptocurrency you wish to purchase and review the order details before confirming.",
            },
            {
              title: "WAIT AND CHECK YOUR WALLET:",
              description: "After placing your buy order, wait for it to be executed by the exchange. This process may happen quickly for market orders or take longer for limit orders, depending on market conditions. Once your order is filled, verify that the purchased cryptocurrency appears in your exchange wallet.",
            },
        ]; //How to Buy Process

        let currentStepIndex = 0; //Initialize current step index

        function updateStepContent(index) {
            if (prcsStpIdx || prcsTitle || prcsDescription) {
                prcsStpIdx.textContent = index + 1;
                prcsTitle.innerHTML = stepsData[index].title;
                prcsDescription.innerHTML = stepsData[index].description;
            }
        }

        //Initialize steps
        for (let i = 0; i < stepsData.length; i++) {
            let prcsLi = document.createElement("li");
            prcsLi.classList.add("prcs-step");
            if (prcsNav) {
                prcsNav.appendChild(prcsLi);
            }

            prcsLi.addEventListener("click", function () {
                document.querySelectorAll(".prcs-step").forEach((item) => {
                    item.classList.remove("active");
                });
                prcsLi.classList.add("active");
                currentStepIndex = i; //Update current step index
                updateStepContent(i);
            });
        }

        //Initial content setup
        updateStepContent(0);
        if (prcsNav) {
            prcsNav.children[0].classList.add("active");
        }

        //Button click handler
        if (prcsNext2) {
            prcsNext2.addEventListener("click", function () {
                currentStepIndex = (currentStepIndex + 1) % stepsData.length;

                // Update active step in navigation
                document.querySelectorAll(".prcs-step").forEach((item) => {
                    item.classList.remove("active");
                });
                prcsNav.children[currentStepIndex].classList.add("active");
                updateStepContent(currentStepIndex);
            });
        }
    },
};
</script>
          
<style scoped></style>
          