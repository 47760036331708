<template>
    <TonConnectUIProvider :options="options">
      <router-view />
    </TonConnectUIProvider>
 </template>
 
 <script>
 import { TonConnectUIProvider } from '@townsquarelabs/ui-vue';
 
 export default {
   components: {
    TonConnectUIProvider
   },
   setup() {
     const options = {
       manifestUrl: "http://gelvercoin.site/tonconnect-manifest.json" 
     };
     console.log("TonConnectUIProvider se está montando con las opciones:", options); 
     return {
       options
     };
   }
 };
 </script>
 
 <style scoped></style>
 